<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <!-- 搜索头部 start -->
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="jobHandlerNameList">
              <input-plus
                v-model="searchForm.jobHandlerNameList"
                valueType="Array"
                placeholder="工单ID"
                title="工单ID"
                label="工单ID"
                clearable
              ></input-plus>
            </el-form-item>
            <el-form-item class="search-item--1" prop="jobHandlerDesc">
              <el-input
                v-model="searchForm.jobHandlerDesc"
                placeholder="任务描述"
                title="任务描述"
              ></el-input>
            </el-form-item>
            <el-form-item class="search-item--1" prop="executeCount">
              <e6-vr-select
                v-model="searchForm.executeCount"
                :data="TaskExecuteCountList"
                placeholder="定时任务执行结果"
                title="定时任务执行结果"
                :props="{
                  id: 'codeValue',
                  label: 'codeName',
                }"
                virtual
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--2" prop="executeDay">
              <e6-date-picker
                popper-class="no-atTheMoment"
                label="执行日期"
                ref="effectiveDatePicker"
                v-model="searchForm.executeDay"
                title="执行日期"
                :picker-options="pickerOptions('searchForm.executeDay')"
                :default-time="['00:00:00', '23:59:59']"
                start-placeholder="执行日期（始）"
                end-placeholder="执行日期（止）"
              ></e6-date-picker>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <!-- 搜索头部 end -->

      <!-- 表格 start -->
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          @selection-change="handleSelectionChange"
          highlight-current-row
          ref="elTable"
          row-key="consumeDetailId"
        >
          <el-table-column type="selection" width="50"></el-table-column>
          <!-- 序号 start -->
          <el-table-column
            label="序号"
            width="80"
            fixed="left"
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <span>
                {{ scope.$index + 1 }}
              </span>
            </template>
          </el-table-column>
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
          </el-table-column>
          <el-table-column
            fixed="right"
            align="center"
            label="操作"
            width="100"
          >
            <template slot-scope="scope">
              <e6-td-operate
                :data="getOperateList(scope.row)"
                @command="handleOperate($event, scope.row)"
              ></e6-td-operate>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 表格 end -->

      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import inputPlus from "@/components/inputPlus";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { listSumPage, findDownList } from "@/api";
export default {
  name: "scheduledTaskLogList",
  components: { inputPlus },
  data() {
    return {
      total: 0,
      loading: false,
      searchForm: {
        jobHandlerDesc: "",
        jobHandlerNameList: [],
        executeDayStart: "",
        executeDayEnd: "",
        executeDay: [],
        executeCount: "",
        pageIndex: 1,
        pageSize: 20,
      },

      TaskExecuteCountList: [],
      columnData: [
        {
          fieldName: "logSumId",
          display: true,
          fieldLabel: "主键",
          width: 100,
          disabled: false,
          fixed: true,
          align: "center",
        },

        {
          fieldName: "jobHandlerName",
          display: true,
          fieldLabel: "任务处理器",
          width: 160,
          disabled: false,
          fixed: true,
          align: "center",
        },
        {
          fieldName: "jobHandlerDesc",
          display: true,
          fieldLabel: "任务描述",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "executeDay",
          display: true,
          fieldLabel: "执行日期",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "successCount",
          display: true,
          fieldLabel: "成功数量",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center",
        },
        {
          fieldName: "failCount",
          display: true,
          fieldLabel: "失败数",
          width: 120,
          disabled: false,
          fixed: false,
          align: "center",
        },
      ], //表头
      tableData: [],
      refTable: "elTable",
      queryListAPI: listSumPage,
      editType: "",
      clickId: "",
      addVisible: false,
      selColumnId: [],
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {
    "searchForm.executeDay": {
      immediate: true,
      handler(val) {
        if (val[0] && val[1] && val[0] > val[1]) {
          this.searchForm.executeDay = this.searchForm.executeDay.reverse();
        }
        this.searchForm.executeDayStart = val[0] || "";
        this.searchForm.executeDayEnd = val[1] || "";
      },
    },
  },
  created() {
    this.initData();
    this.queryList();
  },
  mounted() {
    this.setTableScrollDOM("elTable");
  },
  activated() {
    this.setScrollRecord();
  },
  methods: {
    async initData() {
      try {
        this.dialogLoading = true;
        let promiseList = [findDownList(["TaskExecuteCount"])];
        let [res] = await Promise.all(promiseList);
        this.TaskExecuteCountList = this.getFreezeResponse(res, {
          path: "data.TaskExecuteCount",
        });
      } catch (error) {
        console.error(error);
      } finally {
        this.dialogLoading = false;
      }
    },
    // 表格勾选时间
    handleSelectionChange(columns) {
      this.selColumnId = columns.map((item) => {
        return item.releaseRecordId;
      });
    },
    // 获取页面操作权限
    getOperateList() {
      // 页面按钮权限
      let operates = [
        {
          label: "详情",
          id: 1,
        },
        {
          label: "编辑",
          id: 2,
        },
      ];
      return operates;
    },
    // 操作--更多
    handleOperate(val, row) {
      this.addVisible = true;
      if (val.id == 1) {
        this.routerPush({
          name: "system/scheduledTaskLogDetail",
          params: {
            refresh: true,
            jobHandlerNameList: [row.jobHandlerName],
            executeDay: new Date(row.executeDay).getTime(),
          },
        });
      }
      //   if (val.id == 2) {
      //     this.editType = 2;
      //   }
      //   this.clickId = row.releaseRecordId;
    },
  },
};
</script>
<style lang="scss" scoped>
/deep/ .edit-customer-dialog {
  .el-form {
    padding: 20px 20px 0;
    .el-input {
      width: 350px;
    }
  }
}
</style>
